import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.css';
import { PrivacyPolicyPage, HomePage } from './pages';

function App() {
  return (
    <RouterProvider router={router} />
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
  },
]);

export default App;
